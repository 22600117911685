export function getNotificationsByUserId(userId: any) {
  return `query{
    getNotificationsByUserId(userId:${userId}){
      id
      read
      userId
      notificationId
      createdAt
      updatedAt
      Notification{
        id
        content
        type
        createdByUserId
        organizationId
        belogsTo
      }
      Organization {
        id
        organizationName
        logo
      }
    }
  }`;
}
