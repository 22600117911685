import { Injectable } from '@angular/core';
import { axiosPost } from './api';
import { getNotificationsByUserId } from '../queries/notifications';
import { markAsRead, muteAndUnmuteNotifications } from '../mutations/notification';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private toastr: ToastrService) {}
  getNotificationsByUserId(userId: any) {
    try {
      return axiosPost(getNotificationsByUserId(userId)).then((response) => {
        if (response.data.errors && response.data.errors.length > 0) {
          this.toastr.error(response.data.errors[0].message);
          return;
        }
        if (response.data.data.getNotificationsByUserId) {
          return response;
        }
      });
    } catch (error) {
      this.toastr.error(error.message);
    }
  }
  markAsRead(id: any) {
    try {
      return axiosPost(markAsRead(id)).then((response) => {
        if (response.data.errors && response.data.errors.length > 0) {
          this.toastr.error(response.data.errors[0].message);
          return;
        }
        if (response.data.data.markAsRead) {
          return response;
        }
      });
    } catch (error) {
      this.toastr.error(error.message);
    }
  }
  muteAndUnmuteNotifications(obj: any) {
    try {
      return axiosPost(muteAndUnmuteNotifications(obj)).then((response) => {
        if (response.data.errors && response.data.errors.length > 0) {
          this.toastr.error(response.data.errors[0].message);
          return;
        }
        if (response.data.data.muteAndUnmuteNotifications) {
          return response;
        }
      });
    } catch (error) {
      this.toastr.error(error.message);
    }
  }
}
