export function markAsRead(id: any) {
  return `mutation{
    markAsRead(id:${id}){
      message
    }
  }`;
}
export function muteAndUnmuteNotifications(obj: any) {
  return `mutation{
    muteAndUnmuteNotifications(userId:${obj.userId}
    organizationId:${obj.organizationId}
    isNotifications:${obj.isNotifications}){
      message
    }
  }`;
}
